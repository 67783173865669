<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div class="nav-icon" v-on:click="back">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">Field Producer Details</div>
                        <div class="subtext pt-2">
                            Tell us about your yourself and your producer capabilities.
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content">
                <div class="general-details-section mt-8">
                    <div class="section-title">
                        How many years of set experience do you currently have?<sup>*</sup>
                    </div>
                    <div class="section-content">
                        <v-radio-group v-model="experience">
                            <v-radio
                                value="1"
                                label="Less than 1 year"
                                hide-details
                                class="radio-button text-purple"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="2"
                                label="1-2 years"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="3"
                                label="3-4 years"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="4"
                                label="More than 5 years"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div class="past-duties-section pt-5">
                    <div class="section-title">Please describe some of your duties on past sets.<sup>*</sup></div>
                    <div class="section-content">
                        <textarea
                            v-model="pastDuties"
                            hide-details
                            outlined
                            no-resize
                            class="past-duties-textarea"
                            rows="4"
                        ></textarea>
                    </div>
                </div>
                <div class="proficient-details-section mt-8">
                    <div class="section-title mb-0">Select what you’re proficient in capturing:<sup>*</sup></div>
                    <div class="proficiency-list">
                            <v-checkbox
                                v-model="proficiencies"
                                label="Live Events"
                                value="Live Events"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Lifestyle"
                                value="Lifestyle"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Real Estate"
                                value="Real Estate"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Interviews"
                                value="Interviews"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Narrative"
                                value="Narrative"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Commercial"
                                value="Commercial"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="proficiencies"
                                label="Cinematic"
                                value="Cinematic"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                            Submit
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'SweepDetails',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      proficiencies: [],
      pastDuties: '',
      experience: '',
    });
  },
  methods: {
    ...mapActions('onboarding', ['saveFieldPreferences']),
    ...mapActions('profile', ['getSavedProfile']),
    async submit() {
      const formData = {};
      formData.pastDuties = this.pastDuties;
      formData.proficiencies = this.proficiencies;
      formData.experience = this.experience;
      this.loading = true;
      const response = await this.saveFieldPreferences(formData);
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
    back() {
      const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.skillsets, 'name');
      if (skillsets.some((r) => ['talent'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/talent-details',
        });
      } else if (skillsets.some((r) => ['photographer', 'videographer'].includes(r.toLowerCase()))) {
        this.$router.push({
          path: '/onboarding/creative/sweep-details',
        });
      } else {
        this.$router.push({
          path: '/onboarding/creative/language-preference',
        });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    const skillsets = this.userDetails.profile && _.map(this.userDetails.profile.skillsets, 'name');
    if (Number(this.userDetails.onboardingState) < 5 || !skillsets.some((r) => ['field producer'].includes(r.toLowerCase()))) {
      this.$router.push({
        path: '/',
      });
    }
    this.pastDuties = _.get(this.userDetails, 'profile.aboutpast', '');
    this.experience = _.get(this.userDetails, 'profile.experience', '');
    this.proficiencies = _.get(this.userDetails, 'profile.fieldProficient', '').split(';');
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  color: #262626;
}
::v-deep.v-input .v-label{
  max-width: fit-content;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label{
  max-width: fit-content !important;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-icon-styles{
  margin-top:15px;
  margin-left:-10px;
}
.nav-header-content{
  margin-top:15px;
  margin-left:10px;
  margin-bottom: 10px;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-left: 5rem;
}
.section-title {
    width: 813px;
    height: 54px;
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: -20px;
}
.section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.equipment-col {
    max-width: 450px;
}
.proficiency-checkbox {
    margin-bottom: 1rem !important;
}

.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.past-duties-textarea {
    border: 1px solid #E0E0E0 !important;
    border-radius: 0.5rem;
    outline: none !important;
    width: 781px;
    height: 149px;
    resize: none;
    padding: 0.5rem;
}
.backIcon {
  cursor: pointer;
}
.v-label {
  color: #262626;
  font-color: #262626;
}
.divider{
  width:100%;
  margin-bottom: 1rem;
  border-top: 1px solid #DEDEDE;
}
@media screen and (min-width: 0px) and (max-width: 1000px) {
  .content-main {
   padding-left: 0px;
  }
  .main {
    margin-top: -1rem;
    padding: 2rem;
    margin-right: 1rem !important;
    width: 100%;
  }
  .nav-icon-styles{
    width:10px;
    height: 16px;
    margin-top:0.5rem;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .main-content {
    margin-left: 1.5rem;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .past-duties-textarea{
    width: 310px;
    height: 149px;
  }
  .section-title {
    width: 319px;
    height: 54px;
    margin: 1rem 0rem;
  }
  .proficient-details-section .section-title{
    margin-bottom: 1rem !important;
  }
  .past-duties-section{
    margin-top:-10px;
  }
  .bs-divider {
    margin: 1rem 0rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider{
    display: none;
  }
}

</style>
